// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-email-sign-up-confirmation-js": () => import("./../../../src/pages/email-sign-up-confirmation.js" /* webpackChunkName: "component---src-pages-email-sign-up-confirmation-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-category-page-category-page-js": () => import("./../../../src/templates/category-page/category-page.js" /* webpackChunkName: "component---src-templates-category-page-category-page-js" */),
  "component---src-templates-images-page-images-page-js": () => import("./../../../src/templates/images-page/images-page.js" /* webpackChunkName: "component---src-templates-images-page-images-page-js" */),
  "component---src-templates-resource-page-resource-page-js": () => import("./../../../src/templates/resource-page/resource-page.js" /* webpackChunkName: "component---src-templates-resource-page-resource-page-js" */)
}

